import '../components/hero-header.less'
import '../components/job-page.less'
import Layout from '../components/layout'
import React from 'react'
import classNames from 'classnames'
import get from 'lodash/get'
import { Button } from '@adobe/react-spectrum'
import { Link, graphql } from 'gatsby'
import { parseDate } from '../utils/parseDate'
import { richTextToHtml } from '../utils/richTextToHtml'

const JobTemplate: React.FC = props => {
    // Getting job
    const job = get(props, 'data.jobById')

    return (
        <Layout>
            <div
                className={classNames('hero_header', 'dark', 'jobheader')}
                style={{ backgroundColor: '#2680EB' }}
            >
                <div className="breadcrumbs">
                    <Link to="/">Home</Link>
                    <Link to="/jobs/">Join us</Link>
                    <a href="#">{job.title}</a>
                </div>
                <div className="title">{job.title}</div>
                <div className="subtitle">{job.shortDescription}</div>
            </div>
            <div className="listpage">
                <div className="columns two_80_20 gap32">
                    <div>
                        <div className="jobdescription">
                            {richTextToHtml(job.description)}
                        </div>
                    </div>
                    <div>
                        <div className="side">
                            {job.applyUrl ? (
                                <section>
                                    <a href={job.applyUrl}>
                                        <Button variant="cta">Apply Now</Button>
                                    </a>
                                </section>
                            ) : (
                                ''
                            )}

                            {job.location ? (
                                <section>
                                    <span className="title">Location:</span>
                                    {job.location.city}
                                </section>
                            ) : (
                                ''
                            )}

                            {job.project ? (
                                <section>
                                    <span className="title">
                                        Related Project:
                                    </span>
                                    <Link to={'/projects/' + job.project.slug}>
                                        {job.project.defaultTitle}
                                    </Link>
                                </section>
                            ) : (
                                ''
                            )}

                            {job.date ? (
                                <section>
                                    <span className="title">Published:</span>
                                    {parseDate(job.date).format('MMM D, Y')}
                                </section>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default JobTemplate

export const pageQuery = graphql`
    query jobById($id: String!) {
        site {
            siteMetadata {
                title
            }
        }
        jobById: cmsJob(id: { eq: $id }) {
            description
            id
            applyUrl
            location {
                city
            }
            project {
                id
                defaultTitle
                slug
            }
            shortDescription
            title
            date
        }
    }
`
